import * as React from 'react';
import styled from 'styled-components';

import { IBodyTitle } from '../../utils/types';
import { textToParagraphs, boldString } from '../../utils/strings';
import { medias } from '../../styles/helpers/mediaQueries';
import theme from '../../styles/theme';

const Wrapper = styled.section`
  max-width: ${theme.smContainerWidth};
  margin: 4rem auto;
  h2 b {
    color: ${theme.colors.accent};
  }
  @media ${medias.tabletMin} {
    display: flex;
    h2 {
      flex: 1 0 250px;
      max-width: 430px;
      margin: 0 1.5rem 0;
    }
    p:first-of-type {
      margin-top: 0;
    }
  }
`;

export const ThankYouIntro: React.SFC<IBodyTitle> = props => {
  return (
    <Wrapper>
      <h2 dangerouslySetInnerHTML={{ __html: boldString(props.title) }} />
      <div>{textToParagraphs(props.body)}</div>
    </Wrapper>
  );
};

ThankYouIntro.displayName = 'ThankYouIntro';

export default ThankYouIntro;
