import * as React from 'react';

import BasicLayout from '../layouts/BasicLayout';
import { graphql } from 'gatsby';
import { IPodcastPreviewData } from '../components/Podcast/PodcastPreview';
import { IPostPreviewProps } from '../templates/PostList/PostPreview';
import HomePodcast from '../templates/Home/components/HomePodcast';
import SocialLinks from '../components/SocialLinks';
import PageMeta from '../components/PageMeta';
import { IBodyTitle } from '../utils/types';
import BootcampSection, {
  IBootcampSectionProps,
} from '../components/ThankYou/BootcampSection';
import GetInContact from '../components/ThankYou/GetInContact';
import ThankYouFirstSection from '../components/ThankYou/ThankYouFirstSection';
import ThankYouIntro from '../components/ThankYou/ThankYouIntro';
import theme from '../styles/theme';
import ThankYouBlog from '../components/ThankYou/ThankYouBlog';
import ThankYouSlack, {
  IThankYouSlackProps,
} from '../components/ThankYou/ThankYouSlack';

interface ISubMessages {
  errBoth: string;
  errMc: string;
  errSlack: string;
  successBoth: string;
  successMc: string;
  successSlack: string;
}

export interface IThankYouPageProps {
  location: {
    state?: {
      mcErr?: boolean;
    };
  };
  data: {
    sanityThankYouPage: {
      subMessages: ISubMessages;
      intro: IBodyTitle;
      slack: IThankYouSlackProps;
      podcast: IBodyTitle;
      blog: IBodyTitle;
      bootcamp: IBootcampSectionProps;
      finalCTA: IBodyTitle;
    };
    postListPage: {
      cta: {
        email: string;
      };
    };
    podcasts: {
      nodes: IPodcastPreviewData[];
    };
    posts: {
      nodes: IPostPreviewProps[];
    };
  };
}

export const ThankYouPage: React.SFC<IThankYouPageProps> = props => {
  let state = props.location.state;
  if (state === null || state === undefined) {
    state = {};
  }
  const { mcErr } = state;
  const {
    podcasts,
    posts,
    sanityThankYouPage: page,
    postListPage,
  } = props.data;
  let message = page.subMessages.successBoth;
  if (mcErr === true) {
    message = page.subMessages.errMc;
  } else {
    message = page.subMessages.successMc;
  }
  return (
    <BasicLayout>
      <PageMeta title="Obrigado por se inscrever" />
      <ThankYouFirstSection message={message} mcErr={mcErr} />
      {mcErr ? null : (
        <>
          <ThankYouIntro {...page.intro} />
          <ThankYouSlack {...page.slack} />
          <div style={{ maxWidth: theme.containerWidth, margin: '6rem auto' }}>
            <HomePodcast
              title={page.podcast.title}
              subtitle={page.podcast.body}
              btnText="Ouvir todos os episódios"
              podcasts={podcasts.nodes.slice(0, 1)}
            />
          </div>
          <ThankYouBlog {...page.blog} post={posts.nodes[0]} />
          <BootcampSection {...page.bootcamp} />
          <GetInContact {...page.finalCTA} email={postListPage.cta.email} />
          <SocialLinks />
        </>
      )}
    </BasicLayout>
  );
};

ThankYouPage.displayName = 'ThankYouPage';

export default ThankYouPage;

export const thankYouPage = graphql`
  query thankYouPage {
    sanityThankYouPage(_id: { eq: "thankYouId" }) {
      subMessages {
        errBoth
        errMc
        errSlack
        successBoth
        successMc
        successSlack
      }
      intro {
        body
        title
      }
      slack {
        body
        title
        image {
          asset {
            fluid(maxWidth: 550) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      podcast {
        body
        title
      }
      blog {
        body
        title
      }
      bootcamp {
        body
        title
        url
        btnText
        image {
          asset {
            fluid(maxWidth: 550) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      finalCTA {
        body
        title
      }
    }
    podcasts: allAnchorEpisode(
      limit: 1
      sort: { fields: isoDate, order: DESC }
    ) {
      totalCount
      nodes {
        title
        isoDate
        contentSnippet
        enclosure {
          url
        }
      }
    }
    postListPage: sanityBlog(_id: { eq: "blogId" }) {
      cta {
        email
      }
    }
    posts: allSanityMediumPost(
      limit: 1
      sort: { fields: publishedAt, order: DESC }
    ) {
      totalCount
      nodes {
        title
        url
        subtitle
        totalClapCount
        previewImageId
        authorName
        author {
          name
          imageId
          username
        }
      }
    }
  }
`;
