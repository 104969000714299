import * as React from 'react';
import styled from 'styled-components';
import { IBodyTitle, IImageField } from '../../utils/types';
import Button from '../Button';
import { textToParagraphs } from '../../utils/strings';
import theme from '../../styles/theme';
import { medias } from '../../styles/helpers/mediaQueries';
import GatsbyImage from 'gatsby-image';

export interface IThankYouSlackProps extends IBodyTitle {
  image: IImageField;
}

const Wrapper = styled.section`
  max-width: ${theme.containerWidth};
  margin: 4rem auto;
  @media ${medias.tabletMin} {
    display: flex;
    align-items: center;
    > div {
      max-width: 550px;
      margin-right: 1rem;
    }
  }
  @media ${medias.largeMin} {
    > div {
      margin-right: 3rem;
    }
  }
`;

const ImageWrapper = styled.div`
  max-width: 550px;
  flex: 1 0 300px;
  position: relative;
  @media ${medias.tabletMin} {
    margin-right: 4rem;
  }
`;

export const ThankYouSlack: React.SFC<IThankYouSlackProps> = props => {
  const { body, title } = props;
  return (
    <Wrapper>
      <div>
        <h2>{title}</h2>
        {textToParagraphs(body)}
        <p>
          <Button url="/slack" isSecondary={true}>
            Cadastrar no Slack
          </Button>
        </p>
      </div>
      {props.image && props.image.asset && props.image.asset.fluid ? (
        <ImageWrapper>
          <GatsbyImage
            fluid={props.image.asset.fluid}
            alt="Screenshot do Slack"
          />
        </ImageWrapper>
      ) : null}
    </Wrapper>
  );
};

ThankYouSlack.displayName = 'ThankYouSlack';

export default ThankYouSlack;
