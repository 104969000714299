import * as React from 'react';
import styled from 'styled-components';
import { IBodyTitle } from '../../utils/types';
import { textToParagraphs, boldString } from '../../utils/strings';

export interface IGetInContactProps extends IBodyTitle {
  email: string;
}

const Wrapper = styled.section`
  text-align: center;
  max-width: 550px;
  margin: 6rem auto;
  p {
    margin: 1rem auto;
  }
  h3 {
    margin: 2rem 0 0;
  }
`;

export const GetInContact: React.SFC<IGetInContactProps> = props => {
  return (
    <Wrapper>
      <h2>{props.title}</h2>
      {textToParagraphs(boldString(props.body))}
      <h3>{props.email}</h3>
    </Wrapper>
  );
};

GetInContact.displayName = 'GetInContact';

export default GetInContact;
