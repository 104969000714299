import * as React from 'react';
import styled from 'styled-components';
import { TitleWrapper } from '../listPages/reusableComponents';
import LogoHorizontal from '../icons/LogoHorizontal';
import { boldString } from '../../utils/strings';
import CTA from '../CTA';

export interface IThankYouFirstSectionProps {
  message: string;
  mcErr?: boolean;
}

const FlexedSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > div:first-of-type {
    flex: 1 0 280px;
    max-width: 400px;
  }
  > div:last-of-type {
    flex: 1 0 280px;
    max-width: 550px;
    text-align: left;
    h1 {
      margin-bottom: 0;
    }
  }
`;

const Content: React.SFC<{ message: string; isCentered?: boolean }> = ({
  message,
  isCentered,
}) => (
  <div>
    <h1 style={{ marginBottom: 0 }}>
      Obrigado por se inscrever no Data Hackers!
    </h1>
    <p
      style={{ marginTop: '.5rem' }}
      className={isCentered ? 'text_center' : ''}
      dangerouslySetInnerHTML={{
        __html: boldString(message),
      }}
    />
  </div>
);

export const ThankYouFirstSection: React.SFC<IThankYouFirstSectionProps> = props => {
  const { message, mcErr } = props;
  return (
    <TitleWrapper>
      <LogoHorizontal width="300px" />
      {mcErr ? (
        <>
          <Content isCentered={true} message={message} />
          <CTA subtitle="Tente se inscrever novamente 😉" />
        </>
      ) : (
        <FlexedSection>
          <div>
            <img
              src="https://media.giphy.com/media/11ISwbgCxEzMyY/giphy.gif"
              alt="GIF de sucesso"
            />
          </div>
          <Content message={message} />
        </FlexedSection>
      )}
    </TitleWrapper>
  );
};

ThankYouFirstSection.displayName = 'ThankYouFirstSection';

export default ThankYouFirstSection;
