import * as React from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';
import { medias } from '../../styles/helpers/mediaQueries';
import { IImageField, IBodyTitle } from '../../utils/types';
import GatsbyImage from 'gatsby-image';
import { textToParagraphs, boldString } from '../../utils/strings';
import Button from '../Button';
import { hexToRgb } from '../../styles/helpers/hexToRgb';

export interface IBootcampSectionProps extends IBodyTitle {
  image: IImageField;
  url: string;
  btnText: string;
}

const Wrapper = styled.section`
  margin: 6rem auto;
  @media ${medias.tabletMin} {
    align-items: center;
    display: flex;
    max-width: ${theme.containerWidth};
    justify-content: center;
  }
`;

const ImageWrapper = styled.div`
  max-width: 550px;
  flex: 1 0 300px;
  position: relative;
  @media ${medias.tabletMin} {
    margin-right: 4rem;
  }
  ::after {
    content: '';
    border-radius: inherit;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${({ color }: { color: string }) =>
      `radial-gradient(closest-side, ${hexToRgb(color, 0.7)}, ${hexToRgb(
        color,
        0.9
      )} 95%, ${hexToRgb(theme.colors.bg, 1)} 200%)`};
    mix-blend-mode: color;
  }
`;

const ContentWrapper = styled.div`
  max-width: 550px;
  h2 {
    margin-top: 0;
  }
`;

export const BootcampSection: React.SFC<IBootcampSectionProps> = props => {
  return (
    <Wrapper>
      {props.image && props.image.asset && props.image.asset.fluid ? (
        <ImageWrapper color="#4A26D2">
          <GatsbyImage fluid={props.image.asset.fluid} alt="Foto do bootcamp" />
        </ImageWrapper>
      ) : null}
      <ContentWrapper>
        <h2>{props.title}</h2>
        {textToParagraphs(boldString(props.body))}
        <Button url={props.url}>{props.btnText}</Button>
      </ContentWrapper>
    </Wrapper>
  );
};

BootcampSection.displayName = 'BootcampSection';

export default BootcampSection;
